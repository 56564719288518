define("discourse/plugins/chat/discourse/components/chat/navbar/actions", ["exports", "@glimmer/component", "@ember/helper", "discourse/plugins/chat/discourse/components/chat/navbar/close-drawer-button", "discourse/plugins/chat/discourse/components/chat/navbar/close-thread-button", "discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button", "discourse/plugins/chat/discourse/components/chat/navbar/full-page-button", "discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button", "discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button", "discourse/plugins/chat/discourse/components/chat/navbar/thread-settings-button", "discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown", "discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button", "discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _closeDrawerButton, _closeThreadButton, _closeThreadsButton, _fullPageButton, _newChannelButton, _openDrawerButton, _threadSettingsButton, _threadTrackingDropdown, _threadsListButton, _toggleDrawerButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatNavbarActions extends _component.default {}
  _exports.default = ChatNavbarActions;
  _class = ChatNavbarActions;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <nav class="c-navbar__actions">
        {{yield
          (hash
            OpenDrawerButton=OpenDrawerButton
            NewChannelButton=NewChannelButton
            ThreadTrackingDropdown=ThreadTrackingDropdown
            CloseThreadButton=CloseThreadButton
            CloseThreadsButton=CloseThreadsButton
            ThreadSettingsButton=ThreadSettingsButton
            ThreadsListButton=ThreadsListButton
            CloseDrawerButton=CloseDrawerButton
            ToggleDrawerButton=ToggleDrawerButton
            FullPageButton=FullPageButton
          )
        }}
      </nav>
    
  */
  {
    "id": "IhCgr9iU",
    "block": "[[[1,\"\\n    \"],[10,\"nav\"],[14,0,\"c-navbar__actions\"],[12],[1,\"\\n      \"],[18,1,[[28,[32,0],null,[[\"OpenDrawerButton\",\"NewChannelButton\",\"ThreadTrackingDropdown\",\"CloseThreadButton\",\"CloseThreadsButton\",\"ThreadSettingsButton\",\"ThreadsListButton\",\"CloseDrawerButton\",\"ToggleDrawerButton\",\"FullPageButton\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],[32,7],[32,8],[32,9],[32,10]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/actions.js",
    "scope": () => [_helper.hash, _openDrawerButton.default, _newChannelButton.default, _threadTrackingDropdown.default, _closeThreadButton.default, _closeThreadsButton.default, _threadSettingsButton.default, _threadsListButton.default, _closeDrawerButton.default, _toggleDrawerButton.default, _fullPageButton.default],
    "isStrictMode": true
  }), _class);
});