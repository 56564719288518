define("discourse/plugins/chat/discourse/components/chat/navbar/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "discourse/helpers/concat-class", "discourse/helpers/noop", "discourse/plugins/chat/discourse/components/chat/navbar/actions", "discourse/plugins/chat/discourse/components/chat/navbar/back-button", "discourse/plugins/chat/discourse/components/chat/navbar/channel-title", "discourse/plugins/chat/discourse/components/chat/navbar/title", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _modifier, _concatClass, _noop, _actions, _backButton, _channelTitle, _title, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatNavbar extends _component.default {}
  _exports.default = ChatNavbar;
  _class = ChatNavbar;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{! template-lint-disable no-invalid-interactive }}
      <div
        class={{concatClass "c-navbar-container" (if @onClick "-clickable")}}
        {{on "click" (if @onClick @onClick (noop))}}
      >
        <nav class="c-navbar">
          {{yield
            (hash
              BackButton=BackButton
              ChannelTitle=ChannelTitle
              Title=Title
              Actions=Actions
            )
          }}
        </nav>
      </div>
    
  */
  {
    "id": "zhIdnAjz",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[16,0,[28,[32,0],[\"c-navbar-container\",[52,[30,1],\"-clickable\"]],null]],[4,[32,1],[\"click\",[52,[30,1],[30,1],[28,[32,2],null,null]]],null],[12],[1,\"\\n      \"],[10,\"nav\"],[14,0,\"c-navbar\"],[12],[1,\"\\n        \"],[18,2,[[28,[32,3],null,[[\"BackButton\",\"ChannelTitle\",\"Title\",\"Actions\"],[[32,4],[32,5],[32,6],[32,7]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@onClick\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/index.js",
    "scope": () => [_concatClass.default, _modifier.on, _noop.default, _helper.hash, _backButton.default, _channelTitle.default, _title.default, _actions.default],
    "isStrictMode": true
  }), _class);
});